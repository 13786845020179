import Vuex from 'vuex'
import Vue from 'vue'
import createPersistedState from 'vuex-persistedstate'
import auth from './store/modules/auth'
import crud from './store/modules/crud'
import ui from './store/modules/ui'

// Load Vuex
Vue.use(Vuex)
// Create store
export default new Vuex.Store({
  modules: {
    auth,
    crud,
    ui,
  },
  plugins: [createPersistedState()],
})
