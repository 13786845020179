import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store'

Vue.use(Router)

const router = new Router({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      component: () => import('@/views/account/Index'),
      children: [
        {
          name: 'Home',
          path: '/',
          component: () => import('@/views/account/Login'),
          meta: { requiresAuth: true },
        },
        {
          name: 'Login',
          path: 'login',
          component: () => import('@/views/account/Login'),
          meta: { guest: true },
        },
      ],
    },
    {
      path: '/admin/',
      component: () => import('@/views/dashboard/Index'),
      meta: { requiresAuth: true },
      children: [
        // Dashboard
        {
          name: 'Dashboard',
          path: '',
          component: () => import('@/views/dashboard/Dashboard'),
        },
        // Pages
        {
          name: 'Lockers',
          path: 'lockers',
          component: () => import('@/views/dashboard/Lockers'),
        },
        {
          name: 'Users',
          path: 'users',
          component: () => import('@/views/dashboard/Users'),
        },
        {
          name: 'Cards',
          path: 'cards',
          component: () => import('@/views/dashboard/Cards'),
        },
         {
          name: 'UpdateLocker',
          path: 'update/:locker_id',
          component: () => import('@/views/dashboard/LockerUpdate'),
        },
        {
          name: 'AllCards',
          path: 'cards/:locker_id',
          component: () => import('@/views/dashboard/Cards'),
        },
        {
          name: 'Doors',
          path: 'doors',
          component: () => import('@/views/dashboard/Doors'),
        },
        {
          name: 'AllDoors',
          path: 'doors/:locker_id',
          component: () => import('@/views/dashboard/Doors'),
        },

        // {
        //   name: 'Webhooks',
        //   path: 'webhooks',
        //   component: () => import('@/views/dashboard/Webhooks'),
        // },
        {
          name: 'Logs',
          path: 'logs',
          component: () => import('@/views/dashboard/Logs'),
        },
        {
          name: 'AllLogs',
          path: 'logs/:locker_id',
          component: () => import('@/views/dashboard/Logs'),
        },

        {
          name: 'Reservations',
          path: 'reservations',
          component: () => import('@/views/dashboard/Reservations'),
        },
        {
          name: 'AllReservations',
          path: 'reservations/:locker_id',
          component: () => import('@/views/dashboard/Reservations'),
        },
        {
          name: 'GetReservation',
          path: 'reservations/:locker_id/:reservation_id',
          component: () => import('@/views/dashboard/Reservations'),
        },
        {
          name: 'Templates',
          path: 'templates',
          component: () => import('@/views/dashboard/Templates'),
        },

        {
          name: 'Projects',
          path: 'projects',
          component: () => import('@/views/dashboard/Projects'),
        },
        {
          name: 'Profile',
          path: 'profile',
          component: () => import('@/views/dashboard/Profile'),
        },

      ],
    },
    {
      path: '*',
      component: () => import('@/views/pages/Index'),
      children: [
        {
          name: '404 Error',
          path: '',
          component: () => import('@/views/pages/Error'),
        },
      ],
    },
  ],
})

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.getters.isAuthenticated) {
      next()
      return
    }
    next('/login')
  } else {
    next()
  }
})

export default router
